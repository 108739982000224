import { useAuthStore } from "~/store/auth"
import { storeToRefs } from "pinia"

export const useCurrentUser = async () => {
    const { user: session } = storeToRefs(useAuthStore())
    
    const { data } = await useAsyncData('currentUser', () => $fetch(`/api/user/cache/${session.value?.id}/${session.value?.company}`))
    
    return data.value
}
